<div id="login-box" class="login-box">
  <form [formGroup]="loginForm" id="login-form" class="login-form" (keydown.enter)="login()">
    <h1>Авторизация пользователя</h1>
    <mat-form-field class="email-input mat-focused">
      <input matInput type="email" placeholder="Ваш емейл" formControlName="email" (keydown)="handleEmailClick()">
      <mat-error *ngIf="loginForm.controls.email.errors?.email">
        Некорректный емейл
      </mat-error>
      <mat-error *ngIf="loginForm.controls.email.errors?.noExist">
        {{loginForm.controls.email.errors?.noExist}}
      </mat-error>
    </mat-form-field>
    <mat-form-field id="password-mat-form-field" class="password-input mat-focused">
      <input id="password-mat-form-field__input" matInput type="password" placeholder="Ваш пароль" formControlName="password">
      <mat-error id="password-mat-form-field__error" *ngIf="_password?.invalid">
        Пароль должен содержать минимум 6 символов
      </mat-error>
    </mat-form-field>
    <button id="login-form__button" type="button" mat-raised-button color="accent" class="login-btn" (click)="login()">Войти</button>
    <a id="login-form__link" routerLink="/registration" mat-button color="primary">Регистрация</a>
  </form>
  <button id="google-oauth-btn" mat-stroked-button color="primary" class="google-btn"
          (click)="this.authService.signInWithGoogle()">Google</button>
</div>

