const handlerTagsError = (array: string[], ) => {
  const isIncludes = Array.from(new Set(array))?.length !== (array?.length ?? 0);
  const lengthExceeded = array.some((tag) => tag.length > 15 );
  if (isIncludes) {
    return ({incorrect: 'У тегов не должно быть одинакового текста'});
  } else if (
    array[0] === ''
  ) {
    return ({required: 'Поле для ввода тегов не должно быть пустым'});
  } else if (
    lengthExceeded
  ) {
    return ({length: 'Длина тега превышена'});
  } else {
    return null;
  }
};

export default handlerTagsError;
