import {Component, DoCheck, OnInit} from '@angular/core';
import { AuthService } from '../services/auth.service';
import config from '../config';
import { AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, DoCheck {

  loginForm!: FormGroup

  constructor(public authService: AuthService, private fb: FormBuilder) {

   }

  ngOnInit(): void {
    this._createForm();
  }

  ngDoCheck() {
    if (this.authService.errorStatusCode === 5) {
      this.loginForm.controls.email.setErrors({ noExist: 'Пользователь с такой электронной почтой не найден'});
    }
  }

  handleEmailClick(): void {
    this.authService.errorStatusCode = 0;
  }

  private _createForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  get _email(): AbstractControl {
    return this.loginForm.get('email');
  }

  get _password(): AbstractControl {
    return this.loginForm.get('password');
  }

  login(): void {
    const data = this.loginForm.value;
    if(this._email?.invalid || this._password?.invalid) {
      return;
    }
    this.authService.auth(data, `${config.url}/auth/login`);
  }

}
