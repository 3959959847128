<mat-toolbar id="tool-bar" class="top-panel" color="primary">
  <div id="nav-buttons" class="icons">
    <button id="nav-buttons__home home-icon" routerLink="/" mat-mini-fab color="basic" class="home-icon">
      <mat-icon id="home-icon__default">home</mat-icon>
    </button>
    <button
      id="nav-buttons__user profile-icon"
      mat-icon-button
      *ngIf="this.authService.currentUserValue?.id"
      [routerLink]="['user', id ]"
      mat-mini-fab
      color="basic"
      class="profile-icon"
    >
      <mat-icon id="profile-icon__default" *ngIf="!avatar">account_circle</mat-icon>
      <img
      id="profile-icon__avatar"
      class="profile-icon__avatar"
      mat-card-avatar
      *ngIf="avatar"
      [src]="avatar"
      alt="avatar"
      />
    </button>
  </div>
  <p
    id="tool-bar__greeting"
    *ngIf="this.authService.currentUserValue?.name"
    class="greeting"
  >
    Привет, {{name}}!
  </p>
  <button
    id="tool-bar__login"
    routerLink="/login"
    mat-raised-button
    color="basic"
    *ngIf="!this.authService.currentUserValue"
  >
    Войти
  </button>
  <button
  id="tool-bar__logout"
  mat-raised-button
  color="basic"
  *ngIf="this.authService.currentUserValue"
  (click)="this.authService.logout()"
>
  Выйти
</button>
  <button
    id="tool-bar__clean"
    routerLink="/"
    mat-raised-button
    color="basic"
    (click)="cleanBaseUrl()"
  >
    Очистить URL
  </button>
</mat-toolbar>
<a  id="up-button" [ngStyle]="{display: display}" class="back_to_top" title="Наверх" (click)="scrollToTop()">↑</a>
<router-outlet></router-outlet>


