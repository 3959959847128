import {Component, Inject, Input, OnInit} from '@angular/core';
import { INews } from '../services/news.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-news',
  templateUrl: './view-news.component.html',
  styleUrls: ['./view-news.component.css']
})
export class ViewNewsComponent implements OnInit {

  stringTag: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: INews) { }

  ngOnInit(): void {
    this.stringTag = this.data.tags.join(' #').replace(/^/, '#');
  }

}
