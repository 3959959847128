import {Component, DoCheck, OnInit} from '@angular/core';
import { AuthService, ICommonResponse } from '../services/auth.service';
import config from '../config';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators, AbstractControl, FormGroupDirective} from '@angular/forms';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit, DoCheck {

  registerForm!: FormGroup;
  selectedFile!: File;
  avatarString: string;

  color: string;
  isSubmitted: boolean;

  constructor(public authService: AuthService, private router: Router, private fb: FormBuilder) {
   }

  ngOnInit(): void {
    this._createForm();
  }

  ngDoCheck(): void {
    if (this.authService.errorStatusCode === 30) {
      this.registerForm.controls.email.setErrors({user: 'User already exists'});
    }
    this.color = '#7b1fa2';
    if (this.registerForm.controls.name.errors && (this.registerForm.controls.name.touched || this.isSubmitted)) {
      this.color = '#f44336';
    }
  }

  handleEmailClick(): void {
    this.authService.errorStatusCode = undefined;
  }

  _createForm(): void {
    this.registerForm = this.fb.group({
      name: this.fb.control('', [ Validators.required ]),
      email: this.fb.control('', [ Validators.required, Validators.email ]),
      password: ['', [Validators.required, Validators.minLength(6)]],
      avatar: this.fb.control(this.avatarString, [ Validators.required ]),
      role: ['base']
    });
  }

  get _name(): AbstractControl {
    return this.registerForm.get('name');
  }


  get _email(): AbstractControl {
    return this.registerForm.get('email');
  }

  get _password(): AbstractControl {
    return this.registerForm.get('password');
  }

  onSubmit(form: FormGroupDirective): void {
    this.isSubmitted = form.submitted;
  }

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.selectedFile = target.files[0] as File;
    this.authService.uploadAvatar(this.selectedFile).subscribe((response: ICommonResponse) => {
      this.avatarString = response.data;
      this.register(this.avatarString)
    });
  }

  register(avatar?: string): void {
    const data = this.registerForm.value;
    data.avatar = avatar;
    if (this.registerForm.invalid
      || this._name?.invalid
      || this._email?.invalid
      || this._password?.invalid
      || !this._name?.value.trim()
      || !this._password?.value.trim()  ) {
      return;
    }
    this.authService.auth(data, `${config.url}/auth/register`);
  }
}
