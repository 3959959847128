import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from './news.service';
import config from '../config';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  selectedFile!: File;
  selectedBackgroundFile!: File;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getUser() {
    return this.http.get<IUser>(`${config.url}/user/info`);
  }

  getUserById(id: string): Observable<object> {
    return this.http.get(`${config.url}/user/${id}`);
  }

  updateImage() {
    const fd = new FormData();
    if (this.selectedFile) {
      fd.append('file', this.selectedFile);
    }
    return this.http.post<IUser>(`${config.url}/file/uploadFile`, fd);
  }

  updateUser(data?: {login: string}): Observable<IUser> {
    if (data) {
      return this.http.put<IUser>(`${config.url}/user`, data);
    }
  }

  deleteUser(): void {
    const isSure = confirm('Вы уверены, что хотите удалить аккаунт?');
    if ( isSure ) {
      this.http.delete<IUser>(`${config.url}/user`)
        .subscribe((user) => {
          this.authService.logout();
        });
    }
  }
}
