<app-search
  (filterSet)="setSearch($event)"
></app-search>

<div id="infinite-scroll"
     class="news-list"
     infinite-scroll
     [infiniteScrollDistance]="scrollDistance"
     [infiniteScrollUpDistance]="scrollUpDistance"
     [infiniteScrollThrottle]="throttle"
     (scrolled)="onScrollDown()">
  <app-news
    class="news-item"
    *ngFor="let newsItem of news"
    [item]="newsItem"
  >
  </app-news>
</div>
