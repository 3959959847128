<h1 mat-dialog-title>Изменить данные</h1>
<div id="edit-user-dialog" mat-dialog-content>
    <h3 mat-dialog-title>Изменить данные</h3>
    <mat-form-field id="mat-form-field-login" appearance="outline" class="login-field mat-focused">
        <mat-label id="mat-form-field-login__description">Логин</mat-label>
        <input id="mat-form-field-login__input" matInput value="data.name" [(ngModel)]="data.name" required>
    </mat-form-field>
    <mat-form-field id="mat-form-field-email" appearance="outline" class="login-field mat-focused">
        <mat-label id="mat-form-field-email__description">Email</mat-label>
        <input id="mat-form-field-email__input" matInput value="data.email" [(ngModel)]="data.email" required>
    </mat-form-field>
    <h3 mat-dialog-title>Изменить фото профиля</h3>
    <label id="label-user-img" for="user-img">Изображение: </label>
    <input id="user-img" type="file" name="image" accept="image/jpeg,image/png" (change)="onFileSelected($event)">
    <h3 class="user-background-img" mat-dialog-title>Изменить изображение фона</h3>
    <label id="label-user-background-img" for="user-background-img">Изображение: </label>
    <input id="user-background-img" type="file" name="image" accept="image/jpeg,image/png" (change)="onBackgroundFileSelected($event)">
</div>
<div id="edit-user-form__actions actions" mat-dialog-actions>
    <button id="actions__edit" mat-raised-button color="primary" (click)="onSave()">Изменить</button>
    <button id="actions__cancel" mat-raised-button color="warn" (click)="onCancel()">Отменить</button>
</div>
