import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import config from '../config';
import { UserService } from './user.service';

export interface INews {
  id?: string;
  userId: string;
  username: string;
  image: string;
  tags: any;
  title: any;
  description: any;
  user?: IUser;
  total: number;
  data?: string;
}
export interface IUser {
  avatar: string;
  email: string;
  id: string;
  name: string;
  role: string;
  token: string;
  news: INews[];
}

export interface ISearchSet {
  searchFilter: string;
  searchString: string;
}

export interface ICommonResponse {
  data: any;
  success: boolean;
  statusCode: number;
}

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  allNews: INews[] = [];

  userNews: INews[] = [];

  users: IUser[] = [];

  url = `${config.url}/news`;

  selectedFile!: File;

  numberOfElements: number;
  data: ISearchSet;

  constructor(private http: HttpClient, private userService: UserService) { }

  getNews(page: number, perPage: number): void {
    this.http.get(`${config.url}/news?page=${page}&perPage=${perPage}`)
    .subscribe((news: ICommonResponse) => {
      this.allNews = this.allNews.concat(news.data.content);
      this.allNews.map((item) => {
        const tags = [];
        item.tags.map((tag) => {
          tags.push(tag.title || tag);
        });
        item.tags = tags;
      });
      this.numberOfElements = news.data.numberOfElements;
    });
  }


  getNewsUser(id: string, page: number, perPage: number, image?: string): void {
   this.http.get(`${this.url}/user/${id}?page=${page}&perPage=${perPage}`)
    .subscribe((news: ICommonResponse) => {
      this.userNews = [...this.userNews, ...news.data.content];
      this.userNews.map((item) => {
        const tags = [];
        item.tags.map((tag) => {
          tags.push(tag.title || tag);
        });
        item.tags = tags;
      });
      this.numberOfElements = news.data.numberOfElements;
    });
  }

  addImage(): Observable<INews> {
    const fd = new FormData();
    fd.append('file', this.selectedFile);
    if (this.selectedFile) {
      return this.http.post<INews>(`${config.url}/file/uploadFile`, fd);
    }
  }

  addNews(data: INews): Observable<INews> {
    return this.http.post<INews>(this.url, data);
  }

  getImage(filename: string): Observable<INews> {
    return this.http.get<INews>(`${config.url}/file/${filename}`);
  }

  deleteNews(userId, id): void {
    const user = JSON.parse(localStorage.getItem('currentUser'));

    if (userId && userId === user.id) {
      this.http.delete<INews[]>(`${config.url}/news/${id}`)
      .subscribe(() => {
        window.location.reload();
      });
    } else {
      alert('Нельзя удалять чужие посты');
    }
  }

  updateNews(data: INews, id: string, page, PageSize): void {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.http.put<INews[]>(`${config.url}/news/${id}`, data).subscribe(() => {
      this.getNewsUser(user.id, page, PageSize);
      window.location.reload();
    },
    (error) => {
      if (error.status === 400) {
        alert('Пост должен соответсвовать установленным правилам, попробуйте, пожалуйста, еще раз');
      }
    });
  }

  getSearchNews(data: any, page: number, perPage: number): void {
    const url = new URL(`${this.url}/find?page=${page}&perPage=${perPage}`);
    if (data.searchFilter === 'tags') {
      const tagsArray = data.searchString.split(' ');
      tagsArray.forEach((tag) => url.searchParams.append('tags', tag));
    } else {
      url.searchParams.append(data.searchFilter, data.searchString);
    }
    this.http.get(url.href)
      .subscribe((news: any) => {
        this.allNews = this.allNews.concat(news.content);
        this.allNews.map((item) => {
          const tags = [];
          item.tags.map((tag) => {
            tags.push(tag.title || tag);
          });
          item.tags = tags;
        });
        this.numberOfElements = news.numberOfElements;
    });
  }
}
