<mat-card id="news-card" class="news-card">
  <div id="news-body" class="news-body">
    <mat-card-header id="news-body-header">
      <mat-card-title id="news-body-header__title" class="news-title">{{item.title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content id="news-body-content">
      <p id="news-body-content__text" class="news-title">{{item.description}}</p>
    </mat-card-content>
    <mat-card-subtitle id="news-body-subtitle">
      <p id="news-body-subtitle__tags" class="news-title">Теги: {{stringTags}}</p>
      <p
        id="news-body-subtitle__link-user"
        *ngIf="item.username"
        class="news-title"
      >
        Автор:
        <a [routerLink]="['user', item.userId]" class="news-title">
          {{item.username}}
        </a>
      </p>
    </mat-card-subtitle>
  </div>
  <div id="image-wrapper" class="news-image" (click)="showViewMode(item)">
    <img alt="image" id="image" *ngIf="item.image" src="{{item.image}}">
  </div>
  <mat-card-actions id="news-body-actions">
    <button
      id="news-body-actions__delete-button"
      *ngIf="isCurrentUser"
      mat-raised-button
      color="primary"
      (click)="this.newsService.deleteNews(item.userId, item.id)"
    >
      Удалить запись
    </button>
    <button
      id="news-body-actions__open-button"
      *ngIf="isCurrentUser"
      mat-raised-button
      color="primary"
      (click)="openEditDialog(item, item.userId, item.id)"
    >
      Обновить запись
    </button>
  </mat-card-actions>
</mat-card>
