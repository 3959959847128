import {Component, OnInit, DoCheck, HostListener} from '@angular/core';
import { AuthService } from './services/auth.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, DoCheck {
  id: string;

  name: string;

  avatar: string;

  display: string;
  pageYOffset: number;

  constructor(public authService: AuthService, private scroll: ViewportScroller) {

  }

  ngOnInit(): void {
    this.display = 'none';
  }

  ngDoCheck(): void {
    this.id = this.authService.currentUserValue?.id;
    this.name = this.authService.currentUserValue?.name;
    this.avatar = this.authService.currentUserValue?.avatar;
  }

  cleanBaseUrl(): void {
    localStorage.removeItem('baseURL');
    localStorage.removeItem('google_id');
  }

  @HostListener('window:scroll', ['$event']) onScroll(): void{
    this.pageYOffset = window.pageYOffset;
    if (this.pageYOffset > 900) {
      this.display = 'block';
    }
  }

  scrollToTop(): void {
    this.scroll.scrollToPosition([0, 0]);
    this.display = 'none';
  }
}
