import { NewsService } from '../services/news.service';
import {Component, DoCheck, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

export interface ISearchSet {
  searchFilter: string;
  searchString: string;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit, DoCheck {
  @Output() filterSet: EventEmitter<ISearchSet> = new EventEmitter();

  numberOfElements: number;
  searchNewsForm: FormGroup;

  constructor(private newsService: NewsService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this._createSearchForm();
  }

  _createSearchForm(): void {
    this.searchNewsForm = this.fb.group({
      searchString: this.fb.control('', [Validators.required, Validators.minLength(3)]),
      searchFilter: ['keywords', [Validators.required]]
    });
  }

  ngDoCheck(): void {
    this.numberOfElements = this.newsService.numberOfElements;
  }

  get _searchString(): AbstractControl {
    return this.searchNewsForm.get('searchString');
  }

  get _searchFilter(): AbstractControl {
    return this.searchNewsForm.get('searchFilter');
  }

  searchNews(): void {
      const data: ISearchSet = {
        searchFilter: this._searchFilter.value,
        searchString: this._searchString.value,
      };
      if (this.searchNewsForm.valid) {
        this.filterSet.emit(data);
      }
    }

    resetSearch(): void {
      window.location.reload();
    }
  }

