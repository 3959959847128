<div id="wrapper-search-form" class="wrapper-search-form">
  <p id="wrapper-search-form__amount" class="wrapper-search-form__amount"> Количество всех новостей: {{numberOfElements}}</p>
  <form id="search-form" (submit)="searchNews()" [formGroup]="searchNewsForm">
    <mat-form-field id="mat-form-field-input" class="mat-focused">
      <input id="mat-form-field__input"
             type="search"
             class="search-input ng-touched mat-focused"
             matInput
             placeholder="Поиск"
             name="search"
             formControlName="searchString"
      >
    </mat-form-field>
    <button id="search-form__button" mat-raised-button color="accent" class="search-btn">
      Искать
    </button>
    <mat-form-field id="mat-form-field-select" class="search-select mat-focused">
      <select formControlName="searchFilter" matNativeControl name="search">
        <option value="keywords">По ключевым словам</option>
        <option value="tags">По тегам</option>
        <option value="author">По автору</option>
      </select>
    </mat-form-field>
    <button mat-raised-button color="accent" class="reset-btn" (click)="resetSearch()">
      Сбросить
    </button>
    <mat-error class="search-input__warning" *ngIf="searchNewsForm.controls.searchString.errors?.minlength && searchNewsForm.touched">
      Длина строки поиска должна быть не меньше 3 символов
    </mat-error>
  </form>
</div>
