import {Component, DoCheck, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IInfiniteScrollEvent } from 'ngx-infinite-scroll';
import {AuthService, ICommonResponse, IResponseWithError} from '../services/auth.service';
import { INews, IUser, NewsService } from '../services/news.service';
import { UserService } from '../services/user.service';
import { AddNewsComponent } from '../add-news/add-news.component';
import { EditUserComponent } from '../edit-user/edit-user.component';
import config from '../config';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})

export class UserComponent implements OnInit, DoCheck {
  id!: string;

  user!: IUser;

  news!: INews[];

  isCurrentUser!: boolean;

  page = 1;

  readonly pageSize = 3;

  numberOfElements: number;

  url: string = config.url;
  currentURL: string;
  throttle = 100;
  scrollDistance = 1;
  scrollUpDistance = 2;

  constructor(private route: ActivatedRoute,
              public userService: UserService,
              public authService: AuthService,
              public dialog: MatDialog,
              public newsService: NewsService) {
  }

  ngOnInit(): void {
    this.newsService.allNews.length = 0;
    this.getUser();
  }

  ngDoCheck(): void {
    this.numberOfElements = this.newsService.numberOfElements;
  }

  getUser(): void {
    this.route.params.subscribe((params) => {
      this.userService.getUserById(params.id).subscribe((response: ICommonResponse) => {
        this.user = response.data;
        this.isCurrentUser = this.authService.currentUserValue?.id === this.user.id;
        if (this.isCurrentUser) {
          this.authService.updateUser(this.user.name, this.user.avatar, this.user.email);
        }
      });
      this.id = params.id;
      this.route.url.subscribe(() => {
        this.newsService.userNews.length = 0;
        this.page = 1;
      });
      this.newsService.getNewsUser(params.id, this.page, this.pageSize);
    });
  }

  openNewsDialog(): void {
    const dialogRef = this.dialog.open(AddNewsComponent, {
      width: '600px',
      data: { user_id: this.user.id },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.newsService.addImage().subscribe((image: INews) => {
          result.image = image.data;
          this.page = 1;
          this.newsService.userNews.length = 0;
          this.newsService.addNews(result).subscribe(() => {
            this.newsService.getNewsUser(result.user_id, this.page, this.pageSize, result.image);
          });
        });
      }
    });
  }

  openEditDialog(): void {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '600px',
      data: {
        name: this.user.name,
        email: this.user.email,
        avatar: this.user.avatar,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        result.role = 'user';
        this.userService.updateImage()
          .pipe(catchError((error) => of(error)))
          .subscribe((response: IResponseWithError) => {
            if (response.error) {
              this.userService.updateUser(result).subscribe(() => {
                this.getUser();
              });
            } else {
              result.avatar = response.data;
              this.userService.updateUser(result).subscribe(() => {
                this.getUser();
              });
            }
          }, () => {
            alert('Исправьте, пожалуйста, логин/пароль/e-mail в соответствии с требованиями');
          });
      }
    });
  }

  onScrollDown(event: IInfiniteScrollEvent): void {
    this.newsService.getNewsUser(this.id, ++this.page, this.pageSize);
  }
}
