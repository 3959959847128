<form id="add-news-form" [formGroup]="addNewsForm">
  <h1 id="add-news-form__title" mat-dialog-title>Добавить новость</h1>
  <div id="add-news-container" mat-dialog-content class="adding-form-fields">
    <mat-form-field id="add-news-container__title title-mat-form-field" appearance="outline" class="title-field mat-focused">
      <mat-label id="title-mat-form-field__description">Заголовок</mat-label>
      <input id="title-mat-form-field__input" matInput formControlName="title" required
             placeholder="Введите заголовок новости, его содержание должно быть от 3 до 160 символов">
    </mat-form-field>
    <mat-form-field id="add-news-container__content content-mat-form-field" appearance="outline" class="content-field mat-focused">
      <mat-label id="content-mat-form-field__description">Текст новости</mat-label>
      <textarea id="content-mat-form-field__text-area" matInput formControlName="description" required
                placeholder="Введите текст новости, его содержание должно быть от 3 до 160 символов"></textarea>
    </mat-form-field>
    <mat-form-field id="add-news-container__tags tags-mat-form-field" appearance="outline" class="tags-field mat-focused">
      <mat-label id="tags-mat-form-field__description">Теги</mat-label>
      <input id="tags-mat-form-field__input"
             matInput
             formControlName="tags"
             required
             placeholder="Содержание должно быть от 1 до 15 символов"
      >
      <mat-hint id="tags-mat-form-field__clue" class="tags-field__info" align="start">Введите теги, разделяя пробелами</mat-hint>
      <mat-error id="tags-mat-form-field__warning-required" *ngIf="addNewsForm.controls['tags'].errors?.required">
        {{addNewsForm.controls['tags'].errors?.required}}
      </mat-error>
      <mat-error id="tags-mat-form-field__warning-length" *ngIf="addNewsForm.controls['tags'].errors?.length">
        {{addNewsForm.controls['tags'].errors?.length}}
      </mat-error>
      <mat-error id="tags-mat-form-field__warning-incorrect" *ngIf="addNewsForm.controls['tags'].errors?.incorrect">
        {{addNewsForm.controls['tags'].errors?.incorrect}}
      </mat-error>
    </mat-form-field>
  </div>
  <label id="add-news-form__image-label" for="news-img">Изображение: </label>
  <input id="news-img" type="file" name="image" accept="image/jpeg,image/png,image/gif,image/webp" (change)="onFileSelected($event)">
  <p id="add-news-form__image-warning"
     [class.warningOn]="!isAllFilled"
     class="warning"
  >
    Нужно заполнить все поля или они должны соотвествовать требованиям!
  </p>
  <div id="add-news-form__actions actions" mat-dialog-actions>
    <button id="actions__add" mat-raised-button color="primary" (click)="onSave()">Добавить</button>
    <button id="actions__cancel" mat-raised-button color="warn" (click)="onCancel()">Отменить</button>
  </div>
</form>
