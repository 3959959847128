import {Component, DoCheck, Inject, Input} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { INews, NewsService } from '../services/news.service';
import handlerTagsError from '../utils/handlerTagsError';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.css']
})
export class EditNewsComponent implements DoCheck {

  selectedFile!: File;
  isAllFilled = true;
  updateNewsForm!: FormGroup;
  arrayForCheck!: string[];
  cloneItems!: INews;

  @Input() item!: INews;

  constructor(
    public dialogRef: MatDialogRef<EditNewsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INews,
    public newsService: NewsService,
    private fb: FormBuilder) {
      this._createForm();
    }

  private _createForm(): void {

    this.item = this.data;

    this.cloneItems = JSON.parse(JSON.stringify(this.item));

    this.updateNewsForm = this.fb.group({
      title: [this.cloneItems.title,
        [Validators.required, Validators.minLength(3), Validators.maxLength(160)]],
      description: [this.cloneItems.description,
        [Validators.required, Validators.minLength(3), Validators.maxLength(160)]],
      tags: [this.cloneItems.tags.join(' '), [Validators.required]],
      image: this.cloneItems.image
    });
  }

  get _title() {
    return this.updateNewsForm.get('title');
  }

  get _description() {
    return this.updateNewsForm.get('description');
  }

  get _tags() {
    const tagsElement = this.updateNewsForm.get('tags').value;

    return tagsElement;
  }

  ngDoCheck(): void {
    this.arrayForCheck = this._tags
      ?.replace(/\s+/g, ' ')
      .trim()
      .split(' ');
    this.updateNewsForm.controls.tags.setErrors(handlerTagsError(this.arrayForCheck));
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.cloneItems = Object.assign(this.updateNewsForm.value);

    this.cloneItems = Object.assign(this.cloneItems, this.updateNewsForm.value);

    if (String(typeof this._tags) === 'srting') {
      this.cloneItems.tags = this._tags.split(' ');
    } else {
      this.cloneItems.tags = this._tags;
    }
    this.cloneItems.description = this._description.value;
    this.cloneItems.title = this._title.value;

    let flag = false;
    if (typeof this._tags === 'string') {
      this.cloneItems.tags.split(' ').map((item) => {
        if (item.length > 15) {
          flag = true;
        }
      });

      this.cloneItems.tags = this.cloneItems.tags.split(' ');
    }

    if (!flag
      && this.updateNewsForm.valid) {
      this.dialogRef.close(this.cloneItems);
      flag = false;
    } else {
      alert('Недопустимый формат');
      flag = false;
    }
  }

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.selectedFile = target.files[0] as File;
    this.newsService.selectedFile = this.selectedFile;
  }
}
