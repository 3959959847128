
import { environment } from '../environments/environment';

const url = new URL(window.location.href);
const objectURL = Object.fromEntries(url.searchParams);
if (objectURL.baseURL) {
  localStorage.setItem('baseURL', objectURL.baseURL);
  localStorage.setItem('google_id', objectURL.google_id);
}

const config: any = {
    baseUrl: environment.baseUrl,
    url: objectURL.baseURL ? objectURL.baseURL : environment.url,
    google_id: objectURL.google_id ? objectURL.google_id : environment.google_id,
};

// http://localhost:3000

// 240264501857-g369k2g6ummicqottt0apltiuhgb1h0h.apps.googleusercontent.com

export default config;
