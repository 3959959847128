import {Component, DoCheck, OnInit} from '@angular/core';
import { ISearchSet } from '../search/search.component';
import {INews, NewsService} from '../services/news.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit, DoCheck {
  searchFilter = 'all';

  searchString = '';

  page = 1;

  readonly pageSize = 3;
  throttle = 300;
  scrollDistance = 3;
  scrollUpDistance = 2;

  news!: INews[];
  searchData!: ISearchSet;

  constructor(
    public newsService: NewsService,
    public authService: AuthService) {}

  ngOnInit(): void {
    this.newsService.userNews.length = 0;
    this.getNews(this.page);

    function getURLParameter(sParam: string): string | undefined {
      const sPageURL = window.location.search.substring(1);
      const sParameterName = sPageURL.split('=');
      if (sParameterName[0] === sParam) {
        return sParameterName[1];
      }
      return undefined;
    }

    const code = getURLParameter('code');

    if (code) {
      this.authService.googleAuth(code);
    }
  }

  ngDoCheck(): void {
    this.news = this.newsService.allNews;

  }

  getNews(page: number) {
    this.newsService.getNews(page, this.pageSize);
  }

  getSearchNews(searchData: ISearchSet, page: number): void {
    this.newsService.getSearchNews(searchData, this.page = this.page + 1, this.pageSize);
  }

  setSearch(data: ISearchSet): void {
    this.searchData = {
      searchFilter: data.searchFilter,
      searchString: data.searchString,
    };
    this.newsService.getSearchNews(this.searchData, 1, 3);
    this.newsService.allNews.length = 0;
  }

  onScrollDown(): void {
    this.searchData?.searchString === undefined
      ? this.getNews(++this.page)
      : this.getSearchNews(this.searchData, this.page);
  }
}
