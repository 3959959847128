import {Component, DoCheck, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl, FormControl} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { INews, NewsService } from '../services/news.service';
import handlerTagsError from '../utils/handlerTagsError';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.css']
})
export class AddNewsComponent implements DoCheck {

  selectedFile!: File;
  isAllFilled = true;
  addNewsForm!: FormGroup;
  arrayForCheck!: any;
  tags: string;

  constructor(
    public dialogRef: MatDialogRef<AddNewsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INews,
    public newsService: NewsService,
    private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm(): void {
    this.addNewsForm = this.fb.group({
      title: ['',
        [Validators.required, Validators.minLength(3), Validators.maxLength(160)]],
      description: ['',
        [Validators.required, Validators.minLength(3), Validators.maxLength(160)]],
      tags: ['',
        [Validators.required, Validators.minLength(1), Validators.maxLength(15)]],
      image: [''],
    });
  }

  ngDoCheck(): void {
    this.arrayForCheck = this._tags.value
      ?.replace(/\s+/g, ' ')
      .trim()
      .split(' ');
    this.addNewsForm.controls.tags.setErrors(handlerTagsError(this.arrayForCheck));
  }

  get _title(): AbstractControl {
    return this.addNewsForm.get('title');
  }

  get _description(): AbstractControl {
    return this.addNewsForm.get('description');
  }

  get _tags(): AbstractControl {
    return  this.addNewsForm.get('tags');
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (!this.selectedFile
      || this._title?.invalid
      || this._description?.invalid
      || !this._title?.value.trim()
      || !this._description?.value.trim()
      || this.addNewsForm.invalid
      ) {

      this.isAllFilled = false;
      return;

    }

    this.data = Object.assign(this.data, this.addNewsForm.value);
    this.data.tags = this._tags.value
      ?.replace(/\s+/g, ' ')
      .trim()
      .split(' ');
    this.data.description = this._description.value;
    this.data.title = this._title.value;

    let flag = false;
    this.data.tags.map((item) => {
      if (item.length > 15) {
        flag = true;
      }
    });

    if (!flag
      && this.data.description.length <= 160
      && this.data.title.length <= 160
      && this.data.description.length >= 3
      && this.data.title.length >= 3) {
      this.dialogRef.close(this.data);
      flag = false;
    } else {
      alert('Недопустимый формат');
      flag = false;
    }
  }

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.selectedFile = target.files[0] as File;
    this.newsService.selectedFile = this.selectedFile;
  }

}
