<form id="edit-news-form" [formGroup]="updateNewsForm">
<h1 id="edit-news-form__title" mat-dialog-title>Изменить новость</h1>
<div id="edit-news-container" mat-dialog-content>
    <h3 mat-dialog-title>Изменить заголовок</h3>
    <mat-form-field id="edit-news-container__title title-mat-form-field" appearance="outline" class="login-field mat-focused">
        <mat-label id="title-mat-form-field__description">Заголовок</mat-label>
        <input id="title-mat-form-field__input" value="data.title" matInput formControlName="title" required>
    </mat-form-field>
    <h3 mat-dialog-title>Изменить текст</h3>
    <mat-form-field id="edit-news-container__content content-mat-form-field" appearance="outline" class="login-field mat-focused">
        <mat-label id="content-mat-form-field__description">Текст</mat-label>
        <input id="content-mat-form-field__input" value="data.description" matInput formControlName="description" required>
    </mat-form-field>
    <h3 mat-dialog-title>Изменить тэги</h3>
    <mat-form-field id="edit-news-container__tags tags-mat-form-field" appearance="outline" class="login-field mat-focused">
      <mat-label id="tags-mat-form-field__description">Тэги</mat-label>
      <input id="tags-mat-form-field__input" matInput formControlName="tags" required>
      <mat-error id="tags-mat-form-field__warning-required" *ngIf="updateNewsForm.controls['tags'].errors?.required">
        {{updateNewsForm.controls['tags'].errors?.required}}
      </mat-error>
      <mat-error id="tags-mat-form-field__warning-length" *ngIf="updateNewsForm.controls['tags'].errors?.length">
        {{updateNewsForm.controls['tags'].errors?.length}}
      </mat-error>
      <mat-error id="tags-mat-form-field__warning-incorrect" *ngIf="updateNewsForm.controls['tags'].errors?.incorrect">
        {{updateNewsForm.controls['tags'].errors?.incorrect}}
      </mat-error>
    </mat-form-field>
    <h3 mat-dialog-title>Изменить фото новости</h3>
    <label id="edit-news-form__image-label" for="news-img">Изображение: </label>
    <input id="news-img-input" type="file" name="image" accept="image/jpeg,image/png" (change)="onFileSelected($event)">
    <div class="news-image-wrapper">
        <img id="news-img" alt="image" *ngIf="item.image" src="{{item.image}}">
    </div>
</div>
<div id="edit-news-form__actions actions" mat-dialog-actions>
    <button id="actions__edit" mat-raised-button color="primary" (click)="onSave()">Изменить</button>
    <button id="actions__cancel" mat-raised-button color="warn" (click)="onCancel()">Отменить</button>
</div>
</form>
