 <div id="news-body" class="news-body">
  <div id="image-wrapper" class="news-image">
    <img alt="image" id="image-wrapper__image" *ngIf="data.image" src="{{data.image}}">
  </div>
  <mat-card-header id="news-body-header" class="mat-card-header">
    <mat-card-title id="news-body-header__title" class="news-title">{{data.title}}</mat-card-title>
  </mat-card-header>
  <mat-card-content id="news-body-content">
    <p id="news-body-content__text" class="news-description">{{data.description}}</p>
  </mat-card-content>
  <mat-card-subtitle id="news-body-subtitle">
    <p class="news-description">Теги: {{stringTag}}</p>
  </mat-card-subtitle>
</div>
