<div id="user-page-container" class="user-page-container">
   <div class="user-page-wrapper">
     <img
       src="https://news-feed.dunice-testing.com/api/v1/file/3c339a0e-283b-4d46-9f6c-7eb40b492f5a.jpeg"
       alt="background"
       class="user-info-background"
     />
     <div id="user-info"
          class="user-info"
          >
       <img
         id="user-info__avatar"
         alt="avatar"
         *ngIf="this.user?.avatar"
         src="{{ this.authService.isGoogleUser ? this.user.avatar : this.user.avatar }}"
         class="user-avatar">
       <img
         id="user-info__default-avatar"
         alt="default avatar"
         *ngIf="!this.user?.avatar"
         src="../../assets/no-avatar.png"
         class="user-avatar">
       <div id="user-info-body" class="user-info-body">
         <h1 class="user-info-body__background"><span>Логин: {{this.user?.name}}</span></h1>
         <h1 class="user-info-body__background"><span>Емейл: {{this.user?.email}}</span></h1>
         <h2 class="user-info-body__background"><span>Количество новостей: {{numberOfElements}}</span></h2>
       </div>
       <div id="user-control-buttons" *ngIf="isCurrentUser" class="user-control-buttons">
         <button id="change-info-btn" class="change-info-btn" (click)="openEditDialog()" mat-raised-button color="primary">Изменить данные</button>
         <button id="add-post-btn" class="add-post-btn" (click)="openNewsDialog()" mat-raised-button color="accent">Добавить новый пост</button>
         <button id="remove-user-btn" class="remove-user-btn" (click)="this.userService.deleteUser()" mat-raised-button color="accent">Удалить пользователя</button>
       </div>
   </div>
    </div>
    <mat-divider></mat-divider>
  <div id="news-list" class="news-list"
       infinite-scroll
       [infiniteScrollDistance]="scrollDistance"
       [infiniteScrollUpDistance]="scrollUpDistance"
       [infiniteScrollThrottle]="throttle"
       (scrolled)="onScrollDown($event)">
    <app-news
      [inputId]="id"
      class="news-item"
      *ngFor="let newsItem of newsService.userNews"
      [item]="newsItem"
    >
    </app-news>
  </div>
</div>
