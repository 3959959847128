import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { INews, NewsService } from '../services/news.service';
import { AuthService } from '../services/auth.service';
import { EditNewsComponent } from '../edit-news/edit-news.component';
import { ViewNewsComponent } from '../view-news/view-news.component';
import config from '../config';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})

export class NewsComponent implements OnInit {
  url: string = config.url;

  id!: string;

  news!: INews[];

  isCurrentUser!: boolean;

  page = 1;
  readonly pageSize = 3;

  stringTags!: string;

  @Input() item!: INews;
  @Input() inputId!: string;

  constructor(public newsService: NewsService, public dialog: MatDialog, private authService: AuthService) { }

  ngOnInit(): void {
    this.isCurrentUser = this.authService.currentUserValue?.id === this.item.userId;
    this.stringTags = this.item.tags.join(' #').replace(/^/, '#');
  }

  showViewMode(item: INews): void {
    this.dialog.open(ViewNewsComponent, {
      data: item,
      panelClass: 'custom-container',
    });
  }

  openEditDialog(item, userId, id): void {
    const user = JSON.parse(localStorage.getItem('currentUser'));

    if (userId && userId !== user.id) {
      alert('Нельзя изменять чужие новости');
    } else {

      const dialogRef = this.dialog.open(EditNewsComponent, {
        width: '600px',
        data: item,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (this.newsService.addImage()) {
            this.newsService.addImage().subscribe((image) => {
              result.image = image.data;
              this.newsService.updateNews(result, id, this.page, this.pageSize);
            });
          } else {
            this.newsService.updateNews(result, id, this.page, this.pageSize);
          }
        }
      });
    }
  }
}
