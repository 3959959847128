<form id="reg-form" [formGroup]="registerForm" #form="ngForm" class="reg-form" (ngSubmit)="onSubmit(form)" (click)="register()">
    <h1>Регистрация пользователя</h1>
    <mat-form-field id="login-mat-form-field" class="login-input mat-focused">
      <mat-label id="login-mat-form-field__description">Ваш логин</mat-label>
        <input id="login-mat-form-field__input name" matInput type="text" formControlName="name">
      <mat-hint
        id="login-mat-form-field__clue"
        [ngStyle]="{color: color}"
        class="login-input__clue">
        Содержание должно быть от 3 до 25 символов
      </mat-hint>
    </mat-form-field>
    <mat-form-field id="email-mat-form-field" class="email-input mat-focused">
        <input
          id="email-mat-form-field__input email"
          matInput
          type="email"
          placeholder="Ваш емейл"
          formControlName="email"
          (keydown)="handleEmailClick()">
        <mat-error id="email-mat-form-field__error_email" *ngIf="this.registerForm.controls.email.errors?.email">
            Некорректный емейл
        </mat-error>
      <mat-error id="email-mat-form-field__error_user" *ngIf="this.registerForm.controls.email.errors?.user">
        Данная почта занята
      </mat-error>
    </mat-form-field>
    <mat-form-field id="password-mat-form-field" class="password-input mat-focused">
        <input id="password-mat-form-field__input password" matInput type="password" placeholder="Ваш пароль" formControlName="password">
        <mat-error id="password-mat-form-field__error" *ngIf="_password?.invalid">
            Пароль должен содержать минимум 6 символов
        </mat-error>
    </mat-form-field>
    <div id="reg-form__avatar reg-form-avatar" class="avatar">
        <label id="reg-form-avatar__label" for="reg-form-avatar__input-file-id">Выберите аватар</label>
        <input formControlName="avatar" class="ng-hide" id="reg-form-avatar__input-file-id" type="file" (change)="onFileSelected($event)" />
        <mat-error id="reg-form-avatar__error"
                   *ngIf="registerForm.controls.avatar.errors?.required && form.submitted">
            Необходимо добавить аватар
        </mat-error>
    </div>
    <button id="reg-form__button" (click)="register()" type="submit" mat-raised-button color="primary" class="reg-btn">Регистрация</button>
    <a id="reg-form-link" routerLink="/login" mat-button color="primary">Уже зарегистрированы? Войти</a>
</form>
