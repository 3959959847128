import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import config from '../config';

export interface IUser {
  id: string;
  email: string;
  avatar: string;
  token?: string;
  name: string;
  role: string;
}

export interface ICommonResponse {
  data: any;
  success: boolean;
  statusCode: number;
}

export interface IResponseWithError extends HttpErrorResponse {
  success: boolean;
  statusCode: number;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isGoogleUser!: boolean;
  selectedFile!: File;
  errorStatusCode: number;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<IUser | null>;

  constructor(private http: HttpClient, private router: Router, private socialAuthService: SocialAuthService) {
    this.currentUserSubject = new BehaviorSubject<IUser | null>(JSON.parse(localStorage.getItem('currentUser') || 'null'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): IUser | null {
    return this.currentUserSubject.value;
  }

  uploadAvatar(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${config.url}/file/uploadFile`, formData);
  }

  auth(data: any, url: string) {
    this.http.post<IUser>(url, data)
    .pipe( catchError(error => of(error)))
      .subscribe((response: IResponseWithError) => {
        if (response.error) {
          this.errorStatusCode = response.error.statusCode;
        }
        if (response.data && response.data.token) {
          localStorage.setItem('currentUser', JSON.stringify(response.data));
          this.currentUserSubject.next(response.data);
          this.router.navigate(['/']);
        }
      });
  }

  googleAuth(code: string): void {
    this.http.post(`${config.url}/auth/google-login`, { code })
      .subscribe((response: ICommonResponse) => {
        if (response.data && response.data.token) {
          localStorage.setItem('currentUser', JSON.stringify(response.data));
          this.currentUserSubject.next(response.data);
          this.isGoogleUser = true;
        }
      });
    this.router.navigate(['/']);
  }

  updateUser(name: string, avatar: string, email: string): void{
    const updatedUser = {
      ...this.currentUserValue,
      name,
      avatar,
      email,
    };
    localStorage.setItem('currentUser', JSON.stringify(updatedUser));
    this.currentUserSubject.next(updatedUser);
  }

  signInWithGoogle(): void {
    document.location.href = `https://news-feed.dunice-testing.com/api/oauth2/authorization/google`;
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.socialAuthService.signOut();
    this.isGoogleUser = false;
    this.router.navigate(['/']);
  }
}
